<template>
  <div>
    <div v-if="ismenu == '0'">
      <v-card class="pa-2 shadow-sm mb-2">
        <div class="align-center text-center cursor-pointer" @click="toggleFn">
          <v-avatar size="60" class="mb-2">
            <v-img
              v-if="user&& user.avatar"
              lazy-src="@/assets/images/detail/red-box.png"
              width="60"
              :src="user.avatar"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5" />
                </v-row>
              </template>
            </v-img>
            <v-img
              v-else
              lazy-src="@/assets/images/detail/user.png"
              width="60"
              class=""
              src="@/assets/images/detail/user.png"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5" />
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <div class="mb-2" v-if="user">
            {{ user.nick_name }}
          </div>
        </div>
        <v-divider></v-divider>
        <v-hover
          v-slot="{ hover }"
          v-for="(itemm, indexx) in menuList"
          :key="indexx"
        >
          <div
            :key="indexx"
            @click="routerPath(itemm, 0)"
            :class="hover || itemm.isSelect ? 'primary--text' : ''"
            class="px-4 py-3 cursor"
          >
            <div class="d-flex align-center">
              <v-icon
                class="mr-2"
                :color="hover || itemm.isSelect ? 'primary' : ''"
                >{{ itemm.icon }}</v-icon
              >
              <span>{{ itemm.name }}</span>
            </div>
          </div>
        </v-hover>
      </v-card>
    </div>
    <div v-else>
      <v-card
        v-for="(item, index) in newmenu"
        :key="index"
        class="pb-1 shadow-sm mb-2"
      >
        <p class="text-sm grey--text text--darken-2 px-5 pt-6">
          {{ item.name }}
        </p>
        <div
          v-for="(itemm, indexx) in item.children"
          :key="indexx"
          @click="routerPath(itemm, 1)"
          :class="[
            itemm.isSelect
              ? 'dashboard-nav-link-active px-4'
              : 'dashboard-nav-link px-4',
          ]"
          style="cursor: pointer"
        >
          <div class="d-flex align-center">
            <v-icon class="mr-1" size="">{{ itemm.img }}</v-icon>
            <span>{{ itemm.name }}</span>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data: () => ({
    is_show_coupons: "",
    drawer: false,
    isMobile: false,
    menuList: [],
    num: "0",
    user: {},
    orderNum: "0",
    ismenu: "0", //是否读取后台自定义菜单
    newmenu: [], //后台自定义菜单
    isShowWeb: false, //是否显示第三方菜单
  }),
  mounted() {
    this.user= this.$store.state.USERINFO;
    this.isMobile = this.$store.state.isMobile;
    this.menuList = [
      {
        name: this.$t("返利订单"),
        path: "/order/fanli-order",
        icon: "mdi-clipboard-text-outline",
        isSelect: false,
        isshow: true,
      },
      {
        name: this.$t("余额提现"),
        path: "/user/Withdrawa",
        icon: "mdi-wallet-outline",
        isSelect: false,
        isshow: true,
      },
      {
        name: this.$t("邀请赚钱"),
        path: "/user/inviteMoney",
        icon: "mdi-format-list-bulleted-square",
        isSelect: false,
        isshow: false,
      },
      {
        name: this.$t("账户设置"),
        path: "/user/accountSet",
        icon: "mdi-account-outline",
        isSelect: false,
        isshow: false,
      },
      {
        name: this.$t("消息通知"),
        path: "/infos/news",
        icon: "mdi-bell-outline",
        isSelect: false,
        isshow: false,
      },
    ];
  },
  methods: {
    sign_out() {},
    toggleFn(){
      this.$router.push({path:'/user/accountSet'})
    },
    routerPath(arr) {
      if (this.ismenu == 0) {
        this.$router.push({ path: arr.path });
        for (const itemm of this.menuList) {
          if (arr.path == itemm.path) {
            itemm.isSelect = true;
          } else {
            itemm.isSelect = false;
          }
        }
      } else {
        this.$router.push({ path: arr.url });
        for (const itemm of this.newmenu) {
          if (arr.url == itemm.url) {
            itemm.isSelect = "true";
          } else {
            itemm.isSelect = "false";
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.dashboard-nav-link {
  color: #2b3445 !important;
  text-decoration: none;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-left: 4px solid #fff;
  margin-bottom: 20px;
}
.dashboard-nav-link-active {
  text-decoration: none;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
</style>
